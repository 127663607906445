import saveAs from 'file-saver'
import config from '../config'
import { AssetT } from '../store/slices/assets.slice'
import { FileResponse } from './ajax.helpers'
import { replaceSpacesWithDashes } from './string.helpers'

export const createJSONparams = (objects: AnySceneObjectT[], type: 'meta' | 'code'): string => {
    let JSONparams: string = ''
    objects.forEach((object) => {
        if (
            object.attributes.find(
                (attr) => attr.property === 'isImageEditable' && attr.value === true
            ) &&
            object.fill.find((fill) => fill.property === 'backgroundImage')
        ) {
            const imageSrc = object.fill.find((fill) => fill.property === 'backgroundImage')
            const match = imageSrc?.value?.match(/\{(.*?)\}/g)
            const url = match ? imageSrc?.value : ''
            JSONparams += `{ "NameNM": "${replaceSpacesWithDashes(
                object.title
            )}", "Value": "${url}", "Kind": "image" }, `
        }

        if (
            object.attributes.find(
                (attr) => attr.property === 'isTextEditable' && attr.value === true
            )
        )
            JSONparams += `{ "NameNM": "${replaceSpacesWithDashes(object.title)}", "Value": "${(
                object as TextI
            ).text
                .replaceAll('"', type === 'meta' ? '\\"' : '\\\\"')
                .replaceAll("'", '&apos')}", "Kind": null }, `
    })

    if (JSONparams.length > 0) {
        JSONparams = JSONparams.substring(0, JSONparams.length - 2) // cut comma and space at the end
    }
    JSONparams = '$$[' + JSONparams + ']$$'
    return JSONparams
}

//-----------------------------
//download file when content is string, (example fileContent = html, fileName = index.html)
//-----------------------------
export const fileDownload = (fileName: string, fileContent: string) => {
    const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' })
    saveAs(blob, fileName)
}

//--------------------------------
//convert File to base64 as string
//--------------------------------
export const fileToBase64 = (file: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
            resolve(fileReader.result as string)
        }

        fileReader.onerror = (error) => {
            reject(error)
        }
    })
}

export const getFileId = (filename: string) => {
    const parts = filename.split('.')
    return parts.length > 1 ? parts[1] : filename
}

export const getFilePath = (file: FileResponse) => {
    if (file.metadata.key?.includes('/images/')) {
        return getImagePath(file)
    } else if (file.metadata.key?.includes('/sequences/')) {
        return getSequencePath(file)
    } else {
        return null
    }
}

export const getFontPath = (asset: AssetT) => {
    const filename = asset.url?.split('/').pop()
    return `assets/fonts/${filename}`
}

export const getImagePath = (file: FileResponse) => {
    return `assets/images/${file.filename.replace('.', `.${file._id}.`)}`
}

export const getSequencePath = (file: FileResponse) => {
    const id = file.metadata.key?.split('/').pop()
    return `assets/sequences/${id}/${file.filename.replace('.', `.${file._id}.`)}`
}

export const S3_URL = `https://s3.${config.awsRegion}.amazonaws.com/${config.bucket}`
export const S3_PREFIX = 'public'

interface ImageMetadata {
    width: number
    height: number
}

export const getImageMetadata = (blob: Blob): Promise<ImageMetadata> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
            const img = new Image()
            img.onload = () => {
                resolve({ width: img.naturalWidth, height: img.naturalHeight })
            }
            img.onerror = reject

            img.src = reader.result as string
        }
        reader.onerror = reject

        reader.readAsDataURL(blob)
    })
}

export const readFile = (file: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.onload = () => {
            resolve(fileReader.result as string)
        }
        fileReader.onerror = (error) => {
            reject(error)
        }
        fileReader.readAsText(file)
    })
}

// Get <meta> of HTML with property equals to "metaName" and return its "content"
export const getMetaTag = (file: any, metaName: string): string => {
    const toNodes = (html: string) => new DOMParser().parseFromString(html, 'text/html')
    const metas = toNodes(file).getElementsByTagName('meta')

    let result: string = ''

    for (let i = 0; i < metas.length; i++) {
        // looking for meta's property, which is equals to metaName
        if (metas[i].getAttribute('property') === metaName) {
            result = metas[i].getAttribute('content') ?? ''
        }
    }
    return result
}
