import MoreIcon from '@mui/icons-material/MoreVertRounded'
import {
    ClickAwayListener,
    Divider,
    Grow,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    PopperPlacementType,
    Tooltip,
} from '@mui/material'
import { MouseEventHandler, useEffect, useRef, useState } from 'react'

export interface Item {
    onClick: MouseEventHandler<HTMLLIElement>
    icon: any
    title: string
    disabled?: boolean
    divider?: boolean
    tooltipTitle?: string
}

interface Props {
    data: Item[]
    title?: string
    placement?: PopperPlacementType
}

const MoreContextMenu = ({ data, title, placement = 'bottom-start' }: Props) => {
    const [open, setOpen] = useState(false)
    const anchorRef = useRef<HTMLButtonElement>(null)

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return

        setOpen(false)
    }
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }
    const prevOpen = useRef(open)
    useEffect(() => {
        prevOpen.current = open
    }, [open])

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        } else if (event.key === 'Escape') {
            setOpen(false)
        }
    }

    return (
        <>
            <Tooltip title={title}>
                <IconButton
                    aria-label="menu"
                    size="large"
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <MoreIcon />
                </IconButton>
            </Tooltip>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement={placement}
                transition
                disablePortal
                sx={{ zIndex: 50 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {data.map((x) => (
                                        <span key={x.title}>
                                            <Tooltip title={x.tooltipTitle}>
                                                <span>
                                                    <MenuItem
                                                        onClick={(event) => {
                                                            x.onClick(event)
                                                            handleClose(event)
                                                        }}
                                                        disabled={x.disabled}
                                                    >
                                                        <ListItemIcon>{x.icon}</ListItemIcon>
                                                        <ListItemText>{x.title}</ListItemText>
                                                    </MenuItem>
                                                    {x.divider && <Divider />}
                                                </span>
                                            </Tooltip>
                                        </span>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

export default MoreContextMenu
