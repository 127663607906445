import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Status } from '../../constants/status'
import { getFile } from '../../helpers/ajax.helpers'
import { AppThunkT } from '../store'

export type FileData = {
    id: string
    blob: Blob
}
interface FileStateT {
    data?: FileData
    status?: string
}

const initialState: FileStateT = {
    status: Status.OK,
}

export const fileSlice = createSlice({
    name: 'file',
    initialState,
    reducers: {
        getFilePending: (state: FileStateT) => {
            state.data = undefined
            state.status = undefined
        },
        getFileFulfilled: (state: FileStateT, action: PayloadAction<FileData | undefined>) => {
            state.data = action.payload
            state.status = Status.OK
        },
        getFileRejected: (state: FileStateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
        processFilePending: (state: FileStateT, action: PayloadAction<FileData>) => {
            state.data = action.payload
        },
        processFileFulfilled: (state: FileStateT) => {
            state.status = Status.OK
        },
    },
})

export const {
    processFilePending,
    getFilePending,
    getFileFulfilled,
    getFileRejected,
    processFileFulfilled,
} = fileSlice.actions

export const getFileAsync =
    (id: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getFilePending())
            const data = await getFile(id)
            dispatch(getFileFulfilled({ id: id, blob: data }))
        } catch (error: any) {
            dispatch(getFileRejected(error.message))
        }
    }

export default fileSlice.reducer
